// Generated by Framer (38d083a)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Hero } from "https://framerusercontent.com/modules/hKjtTuWGYB451ckw6eTN/bbq95ZQZ142lE2fXscHd/Hero.js";
const HeroFonts = getFonts(Hero);

const enabledGestures = {mkd5NDEiH: {pressed: true}};

const cycleOrder = ["mkd5NDEiH", "FG2fhxewk", "SlsIYDNGg"];

const serializationHash = "framer-oaOZ0"

const variantClassNames = {FG2fhxewk: "framer-v-1b46anl", mkd5NDEiH: "framer-v-p7acs", SlsIYDNGg: "framer-v-105oqj1"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {large: "FG2fhxewk", medium: "mkd5NDEiH", play: "SlsIYDNGg"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "mkd5NDEiH"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "mkd5NDEiH", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-p7acs", className, classNames)} data-framer-name={"medium"} layoutDependency={layoutDependency} layoutId={"mkd5NDEiH"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-c5030bf8-03e8-4ed5-8156-8ebb4b5627b7, rgb(20, 20, 31))", borderBottomLeftRadius: 999, borderBottomRightRadius: 999, borderTopLeftRadius: 999, borderTopRightRadius: 999, ...style}} variants={{SlsIYDNGg: {backgroundColor: "var(--token-8615bb66-28b1-49d0-9055-8a66863d9289, rgb(250, 250, 250))"}}} {...addPropertyOverrides({"mkd5NDEiH-pressed": {"data-framer-name": undefined}, FG2fhxewk: {"data-framer-name": "large"}, SlsIYDNGg: {"data-framer-name": "play"}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><motion.div className={"framer-10whp3s-container"} layoutDependency={layoutDependency} layoutId={"VeRznjC_r-container"}><Hero color={"var(--token-c5030bf8-03e8-4ed5-8156-8ebb4b5627b7, rgb(20, 20, 31))"} height={"100%"} iconSearch={"Home"} iconSelection={"Play"} id={"VeRznjC_r"} layoutId={"VeRznjC_r"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-oaOZ0.framer-aehzjl, .framer-oaOZ0 .framer-aehzjl { display: block; }", ".framer-oaOZ0.framer-p7acs { cursor: pointer; height: 28px; overflow: hidden; position: relative; width: 28px; will-change: var(--framer-will-change-override, transform); }", ".framer-oaOZ0 .framer-10whp3s-container { flex: none; height: 16px; left: calc(50.00000000000002% - 16px / 2); position: absolute; top: calc(50.00000000000002% - 16px / 2); width: 16px; }", ".framer-oaOZ0.framer-v-1b46anl.framer-p7acs { aspect-ratio: 1 / 1; cursor: unset; height: var(--framer-aspect-ratio-supported, 40px); width: 40px; }", ".framer-oaOZ0.framer-v-105oqj1.framer-p7acs { aspect-ratio: 1 / 1; cursor: unset; height: var(--framer-aspect-ratio-supported, 56px); width: 56px; }", ".framer-oaOZ0.framer-v-105oqj1 .framer-10whp3s-container { height: 32px; left: calc(50.00000000000002% - 32px / 2); top: calc(50.00000000000002% - 32px / 2); width: 32px; }", ".framer-oaOZ0.framer-v-p7acs.pressed.framer-p7acs { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 20px); width: 20px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 28
 * @framerIntrinsicWidth 28
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"FG2fhxewk":{"layout":["fixed","fixed"]},"SlsIYDNGg":{"layout":["fixed","fixed"]},"pY9lkgGEr":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramertsimOgaM2: React.ComponentType<Props> = withCSS(Component, css, "framer-oaOZ0") as typeof Component;
export default FramertsimOgaM2;

FramertsimOgaM2.displayName = "cursor";

FramertsimOgaM2.defaultProps = {height: 28, width: 28};

addPropertyControls(FramertsimOgaM2, {variant: {options: ["mkd5NDEiH", "FG2fhxewk", "SlsIYDNGg"], optionTitles: ["medium", "large", "play"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramertsimOgaM2, [{explicitInter: true, fonts: []}, ...HeroFonts], {supportsExplicitInterCodegen: true})